import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import { Row, Col } from "reactstrap"
import { media} from "utils/Media"
import styled from "styled-components"
import HeadingSpecial from "components/shared/HeadingSpecial"
import BlueBgText from "components/shared/BlueBgText"

const RoleWrap = styled(Col)`
    padding: 0 !important;

    @media ${media.sm} {
        padding: 0 1rem 2rem 1rem !important;
    }

    @media ${media.md} {
        padding: 0 1rem 2rem 1rem !important;
    }

    @media ${media.xl} {
        padding: 0 3rem 2rem 3rem !important;
    }
    
    p {
        padding: 0 1rem;
    }
`

const RoleName = styled.h4`
    position: relative;
    text-align: center;
    margin-bottom: 1rem;
    font-family: ${props => props.theme.font.family.special};
    color: ${props => props.theme.colors.navy};
`

const AuditionsRoles = () => (
    // Query all sites
    <StaticQuery
        query={graphql`
            query {
                allAuditionsRolesJson {
                    edges {
                        node {
                            name
                            description
                        }
                    }
                }
            }
		`}
        render={data => (
            <>
                <Auditions data={data} />
            </>
        )}
    />
)

class Auditions extends Component {
    render() {
        const roles = this.props.data.allAuditionsRolesJson.edges.map(({ node }, i) => {
            return (
                <RoleWrap 
                    key={i}
                    xs={10}
                    sm={6}
                    lg={4}
                >
                    <RoleName>
                        {/* <BgRoleName src={bgRoleName} alt="" /> */}
                        <BlueBgText>{node.name}</BlueBgText>
                    </RoleName>
                    <p>{node.description}</p>
                </RoleWrap>
            )
        })

        return(
            <>
                <HeadingSpecial headingTag="h2" title="WE ARE SEEKING THE FOLLOWING ROLES<br class='d-none d-lg-block'/> AND/OR COVERS" />

                <Row className="py-5 justify-content-center">
                    {roles}
                </Row>

                <HeadingSpecial headingTag="h2" title="PERFORMERS OF ALL CULTURAL BACKGROUNDS <br class='d-none d-lg-block'/>ARE SOUGHT FOR ALL ROLES" lines={false} />

                <Row className="justify-content-center">
                    <BlueBgText md={10} lg={7}>
                        <div className="py-3">
                            <h5>WHAT TO PREPARE</h5>
                            <p>Please bring sheet music for 16 bars of a contemporary musical theatre song (an accompanist will be present). Please bring a CV including photo. There is no need to submit your detail before your audition, we will take them on the day.</p>
                        </div>
                    </BlueBgText>
                </Row>
            </>
        )
    }
}

export default AuditionsRoles