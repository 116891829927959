import React from "react"
import { Col } from "reactstrap"
import styled from "styled-components"
import bgText from "images/bg-text.png"
import { media } from "utils/Media"

const BlueBgTextWrap = styled(Col)`
    padding: 1.5rem 1rem;
    margin: 1rem 0;
    
    @media ${media.sm} {
        padding: 1.5rem 4rem;
        margin: 1rem;
    }

    h5 {
        color: ${props => props.theme.colors.navy};
    }

    p {
        &:last-child {
            margin: 0;
        }
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
`

const BlueBgText = (props) => (
    <>
        <BlueBgTextWrap {...props}>
            <img src={bgText} alt="" />
            {props.children}
        </BlueBgTextWrap>
    </>
)

export default BlueBgText