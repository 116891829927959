import styled from "styled-components"
import { Container } from "reactstrap"
 
const SectionContainer = styled(Container)`
    padding-top: 2rem;
    padding-bottom: 2rem;
    position: relative;
    z-index: 2;
`

export default SectionContainer