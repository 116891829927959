import React, {Component} from "react"
import {StaticQuery, graphql} from "gatsby"
import {Row, Col} from "reactstrap"
import styled from "styled-components"
import HeadingSpecial from "components/shared/HeadingSpecial"
import qImg from "images/q.svg"

const Faq = styled(Col)`
    padding-bottom: 2rem;
    padding-left: 4rem;

    h5 {
        color: ${props => props.theme.colors.navy};
        margin-bottom: 1rem;
     }
`

const QImg = styled.img`
    position: absolute;
    top: -4px;
    left: 10px;
    width: 40px;
    height: 40px;
    display: block;
    
    @media (min-width: 576px) {
        top: -12px;
        left: -20px;
        width: 70px;
        height: 70px;
    }
`

const AuditionsFaqs = () => (
    <StaticQuery
        query={graphql`
            query {
                allAuditionsFaqsJson {
                    edges {
                        node {
                            question
                            answerHTML
                        }
                    }
                }
            }
		`}
        render={data => (
            <Auditions data={data}/>
        )}
    />
)

class Auditions extends Component {
    render() {

        const faqs = this.props.data.allAuditionsFaqsJson.edges.map(({node}, i) => {
            return (
                <Faq md={6} key={i}>
                    <h5>{node.question}</h5>
                    <div dangerouslySetInnerHTML={{__html: node.answerHTML}}/>
                    <QImg src={qImg} alt=""/>
                </Faq>
            )
        })

        return (
            <>
                <HeadingSpecial headingTag="h2" title="FREQUENTLY ASKED QUESTIONS"/>
                <Row className="pt-5">
                    {faqs}
                </Row>
            </>
        )
    }
}

export default AuditionsFaqs