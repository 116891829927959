import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import 'components/shared/GatsbyImage'

const BgWrap = styled.div`
    position: relative;
`

const BgImg = styled(GatsbyImage)`
    position: absolute !important;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`

const Content = styled.div`
    position: relative;
    z-index: 2;
`

const BlueBg = ({children}) => (
    <StaticQuery
        query={graphql`
       
			query {
				blueBgImage: file(relativePath: { eq: "bg-blue-1600.jpg" }) {
					...fluidImage
				}
				blueBgImageMd: file(relativePath: { eq: "bg-blue-md.jpg" }) {
					...fluidImageTablet
				}
				blueBgImageSm: file(relativePath: { eq: "bg-blue-sm.jpg" }) {
					...fluidImageMobile
				}
			}
    	`}
        render={data => (
            <BgWrap>
                <BgImg 
                    fluid={data.blueBgImage.childImageSharp.gatsbyImageData}
                    alt="" 
                    objectFit="cover"
                    objectPosition="50% bottom"
                    className="d-none d-lg-block"
                />
                <BgImg
                    fluid={data.blueBgImageMd.childImageSharp.gatsbyImageData}
                    alt=""
                    objectFit="cover"
                    objectPosition="50% bottom"
                    className="d-none d-sm-block d-lg-none"
                />
                <BgImg
                    fluid={data.blueBgImageSm.childImageSharp.gatsbyImageData}
                    alt=""
                    objectFit="cover"
                    objectPosition="50% bottom"
                    className="d-block d-sm-none"
                />
                <Content>
                    {children}
                </Content>
            </BgWrap>
        )}
    />
)

export default BlueBg