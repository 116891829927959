import React, {Component} from 'react';
import styled from "styled-components"
import {Container} from "reactstrap"
import {media} from "utils/Media"

import Layout from "components/Layout/Layout"
import SectionContainer from "components/shared/SectionContainer"

import BlueBg from "components/shared/BlueBg"
import TitleTreatment from "components/shared/TitleTreatment"
import AuditionsMap from "components/AuditionsPage/AuditionsMap"
import AuditionsRoles from "components/AuditionsPage/AuditionsRoles"
import BottomImage from "components/shared/BottomImage"
import AuditionsFaqs from "components/AuditionsPage/AuditionsFaqs"
import faqBgSm from "images/bg-texture-sm.png"
import faqBg from "images/bg-texture.png"

const LogoContainer = styled(Container)`
    max-width: 1030px !important;
    padding-top: 1rem;
    padding-bottom: 2rem;
`

const FaqWrapper = styled.div`

    background-image: url('${faqBgSm}');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 500px;
    
    @media ${media.md} {
        background-position: center 260px;
    }
    
    @media ${media.lg} {
        background-image: url('${faqBg}');
        background-size: cover;
    }
    
    @media ${media.xl} {
        background-position: center 60px;
    }
`

class Auditions extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <Layout pageName="auditions" slug="auditions" title="Auditions">
                <BlueBg>
                    <LogoContainer>
                        <TitleTreatment/>
                    </LogoContainer>
                </BlueBg>

                <SectionContainer>
                    <AuditionsMap/>
                </SectionContainer>

                <SectionContainer>
                    <AuditionsRoles/>
                </SectionContainer>

                <BottomImage/>

                <FaqWrapper>
                    <SectionContainer>
                        <AuditionsFaqs/>
                    </SectionContainer>
                </FaqWrapper>
            </Layout>
        );
    }
}

export default Auditions;