import React from "react"
import {StaticQuery, graphql} from "gatsby"
import styled from "styled-components"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import 'components/shared/GatsbyImage'

const BottomImage = () => (
    <StaticQuery
        query={graphql`
			query {
				bottomImage: file(relativePath: { eq: "bg-bottomimage-xl.jpg" }) {
					...fluidImage
				}
				bottomImageSm: file(relativePath: { eq: "bg-bottomimage-md.jpg" }) {
					...fluidImageTablet
				}
			}
    	`}
        render={data => (
            <>
                <GatsbyImage image={data.bottomImage.childImageSharp.gatsbyImageData}
                    alt=""
                    className="d-none d-lg-block"
                />
                <GatsbyImage image={data.bottomImageSm.childImageSharp.gatsbyImageData}
                    alt=""
                    className="d-none d-sm-block d-lg-none"
                />
            </>
        )}
    />
)

export default BottomImage