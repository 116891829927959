import React, { Component } from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { media } from "utils/Media"

import HeadingSpecial from "components/shared/HeadingSpecial"

import ukMap from "images/icy-uk.png"
import mapPinActive from "images/map-pin-active.png"
import mapPin from "images/map-pin.png"
import bgInfoLocation from "images/bg-location.svg"

const SelectedCity = styled.div`
    position: absolute;
    z-index: 10; 
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 320px;
    height: 190px;
    background-image: url("${bgInfoLocation}");
    background-repeat: no-repeat;
    background-size: contain;
    padding: 2rem 3rem 0;
    font-size: ${props => props.theme.font.size.sm};
    line-height: 1.2;
    background-position: center;
    
    @media ${media.md} {
        transform: none;
        left: 0;
        top: 20%;
        width: 440px;
        height: 230px;
        padding: 2rem 3rem;
        font-size: ${props => props.theme.font.size.md};
    }

    h5 {
        color: ${props => props.theme.colors.navy};
        font-size: ${props => props.theme.font.h6.size};
        margin-bottom: .5rem;
        padding: 0;
    }
`

const MapPin = styled.img`
    position: absolute;
    width: 30px;
    @media ${media.sm} {
        width: 56px;
    }
`

const Close = styled.button`
    background-color: transparent;
    border: 0;
    width: 25px;
    height: 25px;
    position: absolute;
    z-index: 1;
    top: 1.5rem;
    right: 2.5rem;

    &:after,
    &:before {
        content: "";
        width: 100%;
        height: 2px;
        top: 50%;
        left: 0;
        position: absolute;
        transform: translateY(-50%) rotate(45deg);
        background-color: ${props => props.theme.colors.black};
    }

    &:after {
        transform: translateY(-50%) rotate(-45deg);
    }
`

const AuditionsMap = () => (
    <StaticQuery
        query={graphql`
            query {
                allAuditionsLocationsJson {
                    edges {
                        node {
                            name
                            date
                            venueHTML
                            signin
                            top
                            left
                        }
                    }
                }
            }
		`}
        render={data => (
            <>
                <Auditions data={data} />
            </>
        )}
    />
)

class Auditions extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            activeLocation: "",
            selectedLocation: false
        }

        this.locationChange = this.locationChange.bind(this)
        this.hideSelectedLocation = this.hideSelectedLocation.bind(this)
    }

    locationChange(i) {
        this.setState({
            activeLocation: i,
            selectedLocation: true
        })
    }

    hideSelectedLocation() {
        this.setState({
            selectedLocation: false,
            activeLocation: ""
        })
    }

    render() {

        const { activeLocation } = this.state
        const locations = this.props.data.allAuditionsLocationsJson.edges

        const pins = locations.map( ({ node }, i) => {
            return(
                <MapPin 
                    src={this.state.activeLocation === i ? mapPinActive : mapPin}
                    key={i}
                    onClick={() => this.locationChange(i)}
                    style={{ top: node.top, left: node.left}}
                />
            )
        })

        return(
            <>
                <HeadingSpecial headingTag="h2" title="OPEN CALLS FOR THE WEST END PRODUCTION WILL TAKE PLACE IN THE FOLLOWING CITIES" />
                <p className="text-center pt-2">Click on map pins</p>
                <Row className="justify-content-center position-relative pt-4">

                    {this.state.selectedLocation &&
                        <SelectedCity>
                            <Row>
                                <Col><h5 className="text-uppercase">{locations[activeLocation].node.name}</h5></Col>
                            </Row>
                            <Row className="pb-1">
                                <Col xs={4} className="pr-0"><strong>Date</strong>:</Col>
                                <Col className="pl-0">{locations[activeLocation].node.date}</Col>
                            </Row>
                            <Row className="pb-1">
                                <Col xs={4} className="pr-0"><strong>Venue</strong>:</Col>
                                <Col className="pl-0"><span dangerouslySetInnerHTML={{ __html: locations[activeLocation].node.venueHTML }} /></Col>
                            </Row>
                            <Row className="pb-1">
                                <Col xs={4} className="pr-0"><strong>Sign in</strong>:</Col>
                                <Col className="pl-0">{locations[activeLocation].node.signin}</Col>
                            </Row>
                            <Close onClick={this.hideSelectedLocation} />
                        </SelectedCity>
                    }

                    <Col xs="auto">
                        <img src={ukMap} alt="Uk" />
                        {pins}
                    </Col>
                </Row>
            </>
        )
    }
}

export default AuditionsMap