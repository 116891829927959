import React from "react"
import { StaticQuery, graphql } from "gatsby"
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import 'components/shared/GatsbyImage'

const TitleTreatment = () => (
    <StaticQuery
        query={graphql`
			query {
				titleTreatmentImage: file(relativePath: { eq: "logo-title-treatment-full.png" }) {
					childImageSharp {
                        gatsbyImageData(
                            width: 1920, 
                            quality: 100,
                            placeholder: NONE
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
				}
			}
    	`}
        render={data => (
            <>
                <GatsbyImage image={data.titleTreatmentImage.childImageSharp.gatsbyImageData} alt="Frozen the Musical" crtical={true} fadeIn={false} />
            </>
        )}
    />
)

export default TitleTreatment